import React, {useState, useEffect} from 'react'

export default function FiturWebsite() {

    const parse = require('html-react-parser');

    const [services, setServices] = useState([])
    const [fitur01, setFitur01] = useState([])
    const [fitur02, setFitur02] = useState([])
    const [fitur03, setFitur03] = useState([])
    const [fitur04, setFitur04] = useState([])
    const [fitur05, setFitur05] = useState([])
    const [fitur06, setFitur06] = useState([])
    const [fitur07, setFitur07] = useState([])
    const [fitur08, setFitur08] = useState([])
    const [fitur09, setFitur09] = useState([])
    const [fitur10, setFitur10] = useState([])

    useEffect(() => {

      const pathname = window.location.pathname.split('/').pop()

        const servicesPost = () => {
            return fetch(`https://aio.wikrea.co.id/wp-json/wp/v2/service?acf_format=standard&slug=${pathname}`)
                .then((response) => response.json())
                .then((data) => {
                    setServices(data[0].acf.fitur_website);
                    setFitur01(data[0].acf.fitur_website.fitur_1)
                    setFitur02(data[0].acf.fitur_website.fitur_2)
                    setFitur03(data[0].acf.fitur_website.fitur_3)
                    setFitur04(data[0].acf.fitur_website.fitur_4)
                    setFitur05(data[0].acf.fitur_website.fitur_5)
                    setFitur06(data[0].acf.fitur_website.fitur_6)
                    setFitur07(data[0].acf.fitur_website.fitur_7)
                    setFitur08(data[0].acf.fitur_website.fitur_8)
                    setFitur09(data[0].acf.fitur_website.fitur_9)
                    setFitur10(data[0].acf.fitur_website.fitur_10)
                });}
                
        servicesPost();

    }, []);

  return (
    <>
        <div className='container-xxl ws-b'>
            <div className='container center'>
                <p className='accent-text sitecolor-primary text-center'>{services.subheading}</p>
                <h3 className='text-center'>{parse(`${services.heading}`)}</h3>
            </div>
            <div className='container table-fitur mt-lg-4'>
                <table className='table table-hover br-5'>
                    <thead>
                        <th scope='col' className='p-4 sitebg-accent w-75'><h4 className='mb-0 text-white'>Fitur Utama</h4></th>
                        <th scope='col' className='p-4 sitebg-secondary w-25 text-center'><h4 className='mb-0 text-white'>Fitur</h4></th>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{fitur01.label}</h6></td>
                            <td className='text-center p-4 bg-light'>{parse(`${fitur01.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{fitur02.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'>{parse(`${fitur02.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{fitur03.label}</h6></td>
                            <td className='text-center p-4 bg-light'>{parse(`${fitur03.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{fitur04.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'>{parse(`${fitur04.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{fitur05.label}</h6></td>
                            <td className='text-center p-4 bg-light'>{parse(`${fitur05.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{fitur06.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'>{parse(`${fitur06.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{fitur07.label}</h6></td>
                            <td className='text-center p-4 bg-light'>{parse(`${fitur07.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{fitur08.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'>{parse(`${fitur08.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{fitur09.label}</h6></td>
                            <td className='text-center p-4 bg-light'>{parse(`${fitur09.font_awesome}`)}</td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{fitur10.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'>{parse(`${fitur10.font_awesome}`)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}
