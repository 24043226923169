import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import ServicesGrid from '../subs/ServicesGrid';


export default function () {

    const [isLoading, setIsLoading] = useState(true);

  const parse = require('html-react-parser');

  // API Services
  const [subheading, setSubheading] = useState([]);
  const [heading, setHeading] = useState([]);
  const [question, setQuestion] = useState ([])
  const [cta, setCta] = useState ([])

  useEffect(() => {

    const portfolioBlock = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2245?acf_format=standard")
            .then((response) => response.json())
            .then((data) => {
              setSubheading(data.acf.subheading);
              setHeading(data.acf.heading);
              setQuestion(data.acf.cta.question)
              setCta(data.acf.cta.button)
  
              setIsLoading(!isLoading)
            });}
            
      portfolioBlock();
  }, []);
  //

  return (
    <div className='container-xxl ws-b'>
        {isLoading? <div className='container center loader-center'>
          <div className='center-1'></div>
          <div className='center-2'></div>
        </div> : 
        <div className='container center mb-4'>
            <p className='accent-text text-center sitecolor-primary'>{subheading}</p>
            <h3 className='text-center'>{parse(`${heading}`)}</h3>
        </div>}
        <ServicesGrid />
        {isLoading? <div className='container center w-50 mt-5'>
             <div className='center-1'></div>
        </div> : 
        <div className='d-flex justify-content-center mt-5'>
            <span className='accent-text sitecolor-text mb-0 me-2'>{question}</span><Link className='hvr-float' type='button' to={`${cta.link}`}><span>{cta.label} <i className='fas fa-right-long ms-1'></i></span></Link>
        </div>}
    </div>
  )
}
