import React, {useState, useEffect} from 'react'
import { useMediaQuery } from "react-responsive";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

export default function PortfolioImageSlide() {

    const [isLoading, setIsLoading] = useState(true);

    // API Portfolio
  const [topgambar, setTopgambar] = useState([]);
  const [bottomgambar, setBottomgambar] = useState([]);


  useEffect(() => {

    const portfolioBlock = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2213?acf_format=standard")
            .then((response) => response.json())
            .then((data) => {
              setTopgambar(data.acf.slider_top);
              setBottomgambar(data.acf.slider_bottom);
  
              setIsLoading(!isLoading)
  
            });}
            
      portfolioBlock();
  }, []);
  //

    const isDesktop = useMediaQuery({
        query: "(min-device-width: 992px)",
      });
      const isTablet = useMediaQuery({ query: "(min-device-width: 768px)" });
      const slideCount = isDesktop ? 4 : isTablet ? 2 : 1 ;

  return (
    <div className='mt-4'>
        <CarouselProvider infinite={true} playDirection={'forward'} visibleSlides={slideCount} totalSlides={8} interval={2000} isPlaying={true} naturalSlideWidth={100} naturalSlideHeight={62} >
            <Slider>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_1}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_2}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_3}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_4}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_5}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_6}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_7}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${topgambar.gambar_8}`} alt=''></img>}
                </Slide>
            </Slider>
        </CarouselProvider>
        <CarouselProvider className='mt-4' infinite={true} playDirection={'backward'} visibleSlides={slideCount} totalSlides={8} interval={2000} isPlaying={true} naturalSlideWidth={100} naturalSlideHeight={62} >
            <Slider>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_1}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_2}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_3}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_4}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_5}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_6}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_7}`} alt=''></img>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : <img src={`${bottomgambar.gambar_8}`} alt=''></img>}
                </Slide>
            </Slider>
        </CarouselProvider>
    </div>
  )
}
