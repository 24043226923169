import React, {useState, useEffect} from 'react'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import Column from '../../utils/Column';

export default function Introduction() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [services, setServices] = useState([])
    const [subheading, setSubheading] = useState([])
    const [slide, setSlide] = useState([])

    useEffect(() => {

      const pathname = window.location.pathname.split('/').pop()

        const servicesPost = () => {
            return fetch(`https://aio.wikrea.co.id/wp-json/wp/v2/service?acf_format=standard&slug=${pathname}`)
                .then((response) => response.json())
                .then((data) => {
                    setServices(data[0].acf.featured);
                    setSubheading(data[0].title.rendered)

                    setIsLoading(!isLoading)
                });}
                
        servicesPost();

        const hqDesign = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2299?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setSlide(data.acf.slide)
                });}
                
        hqDesign();

    }, []);


  return (
    <>
        <div className='container-xxl ws-tb'>
            {isLoading ? <div className="loader-left loader-dynamic d-flex align-items-center" role="status">
                <div className='container-xxl pe-lg-5'>
                    <div className='lh-first'></div>
                    <div className='lh-second'></div>
                    <div className='lh-last'></div>
                </div>
            </div> : 
            <div className='row'>
                <Column isLeftFirst>
                    <p className='accent-text sitecolor-primary'>{subheading}</p>
                    <h3>{parse(`${services.heading}`)}</h3>
                    <p>{services.deskripsi}</p>
                    <div>
                        <ul className='p-0'>
                            <li className='py-2'>
                                <i className='fas fa-circle-check me-3 sitecolor-primary'></i>
                                <span className='sitecolor-text'>{services.list_1}</span>
                            </li>
                            <li className='py-2'>
                                <i className='fas fa-circle-check me-3 sitecolor-primary'></i>
                                <span className='sitecolor-text'>{services.list_2}</span>
                            </li>
                            <li className='py-2'>
                                <i className='fas fa-circle-check me-3 sitecolor-primary'></i>
                                <span className='sitecolor-text'>{services.list_3}</span>
                            </li>
                            <li className='py-2'>
                                <i className='fas fa-circle-check me-3 sitecolor-primary'></i>
                                <span className='sitecolor-text'>{services.list_4}</span>
                            </li>
                        </ul>
                    </div>
                </Column>
                <Column className='d-flex align-items-center' isRightFirst>
                    <div className='w-100'>
                        <CarouselProvider infinite={true} touchEnabled={false} dragEnabled={false} playDirection={'forward'} totalSlides={4} interval={2000} isPlaying={true} naturalSlideWidth={100} naturalSlideHeight={55}>
                            <Slider classNameAnimation="slider-design">
                                <Slide>
                                    <img className='d-block w-100' src={`${slide.gambar_1}`} alt=''></img>
                                </Slide>
                                <Slide>
                                    <img className='d-block w-100' src={`${slide.gambar_2}`} alt=''></img>
                                </Slide>
                                <Slide>
                                    <img className='d-block w-100' src={`${slide.gambar_3}`} alt=''></img>
                                </Slide>
                                <Slide>
                                    <img className='d-block w-100' src={`${slide.gambar_4}`} alt=''></img>
                                </Slide>
                            </Slider>
                        </CarouselProvider>
                     </div>
                </Column>
            </div>}
        </div>
    </>
  )
}
