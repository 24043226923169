import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import SocialIcon from '../subs/SocialIcon'

export default function Footer() {

    const parse = require('html-react-parser');

    const [isLoading, setIsLoading] = useState(true);

    // API Footer Menu
    const [information, setInformation] = useState([]);
    const [emaillist, setEmaillist] = useState([]);
    const [phonelist, setPhonelist] = useState([]);
    const [heading01, setHeading01] = useState([]);
    const [kol2list01, setKol2list01] = useState([]);
    const [kol2list02, setKol2list02] = useState([]);
    const [kol2list03, setKol2list03] = useState([]);
    const [kol2list04, setKol2list04] = useState([]);
    const [kol2list05, setKol2list05] = useState([]);
    const [heading02, setHeading02] = useState([]);
    const [kol3list01, setKol3list01] = useState([]);
    const [kol3list02, setKol3list02] = useState([]);
    const [kol3list03, setKol3list03] = useState([]);
    const [kol3list04, setKol3list04] = useState([]);
    const [kol3list05, setKol3list05] = useState([]);
    const [heading03, setHeading03] = useState([]);
    const [company01, setCompany01] = useState([]);
    const [company02, setCompany02] = useState([]);
    const [creditfooter, setCreditfooter] = useState([]);


    useEffect(() => {

        const footerMenu = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/2143?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setInformation(data.acf.information);
                    setEmaillist(data.acf.information.info_list.email_info);
                    setPhonelist(data.acf.information.info_list.telephone);
                    setHeading01(data.acf.kolom_2.heading);
                    setKol2list01(data.acf.kolom_2.list_1)
                    setKol2list02(data.acf.kolom_2.list_2)
                    setKol2list03(data.acf.kolom_2.list_3)
                    setKol2list04(data.acf.kolom_2.list_4)
                    setKol2list05(data.acf.kolom_2.list_5)
                    setHeading02(data.acf.kolom_3.heading);
                    setKol3list01(data.acf.kolom_3.list_1)
                    setKol3list02(data.acf.kolom_3.list_2)
                    setKol3list03(data.acf.kolom_3.list_3)
                    setKol3list04(data.acf.kolom_3.list_4)
                    setKol3list05(data.acf.kolom_3.list_5)
                    setHeading03(data.acf.company.heading);
                    setCompany01(data.acf.company.info_1)
                    setCompany02(data.acf.company.info_2)
                    setCreditfooter(data.acf.credit_footer);
    
    
                    setIsLoading(!isLoading);
                });}
                
        footerMenu();
    }, []);
    // End API Footer Menu

  return (
    <div className='container-fluid p-0 sitebg-whitesmoke mt-n2'>
        <div className='after-header-shape bottom'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="shape-hero-1" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                <path className="shape-hero-1" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                <path className="shape-hero-1" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
            </svg>
        </div>
        <div className='container-xxl pt-5 pb-4'>
            <div className='row row-cols-lg-4 row-cols-1 mt-lg-5 mb-lg-5 mt-4'>
                <div className='col-lg-5 pe-lg-5 ps-0 px-0'>
                    <div className='footer-logo mb-4'>
                        <Link to='/'>{isLoading ? <div className="spinner-border text-white" role="status"><span className="sr-only">Loading...</span></div> : <img src={`${information.logo}`} alt=''></img>}</Link>
                    </div>
                    <p>{parse(`${information.info_text}`)}</p>
                    <ul className='p-0 footer-link'>
                        <li className='py-2'><a className='mb-0' type='button' href={`mailto:${emaillist}`}><i className='fas fa-envelope icon-small me-3 sitecolor-primary'></i><span className='text-body sitecolor-text'>{emaillist}</span></a></li>
                        <li className='py-2'><a className='mb-0' type='button' href={`${phonelist.link}`}><i className='fas fa-phone-square icon-small me-3 sitecolor-primary'></i><span className='text-body sitecolor-text'>{phonelist.label}</span></a></li>
                    </ul>
                </div>
                <div className='col-lg-1 px-0'>

                </div>
                <div className='col-lg-2 px-0'>
                    <h5>{heading01}</h5>
                    <hr className='divider-footer sitecolor-primary opacity-100 w-25 mb-3'></hr>
                    <ul className='p-0 footer-link'>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol2list01.link}`}><span className='text-body sitecolor-text'>{kol2list01.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol2list02.link}`}><span className='text-body sitecolor-text'>{kol2list02.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol2list03.link}`}><span className='text-body sitecolor-text'>{kol2list03.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol2list04.link}`}><span className='text-body sitecolor-text'>{kol2list04.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol2list05.link}`}><span className='text-body sitecolor-text'>{kol2list05.label}</span></Link></li>
                    </ul>
                </div>
                <div className='col-lg-2 px-0'>
                    <h5>{heading02}</h5>
                    <hr className='divider-footer sitecolor-primary opacity-100 w-25 mb-3'></hr>
                    <ul className='p-0 footer-link'>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol3list01.link}`}><span className='text-body sitecolor-text'>{kol3list01.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol3list02.link}`}><span className='text-body sitecolor-text'>{kol3list02.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol3list03.link}`}><span className='text-body sitecolor-text'>{kol3list03.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol3list04.link}`}><span className='text-body sitecolor-text'>{kol3list04.label}</span></Link></li>
                        <li className='py-2'><Link className='mb-0' type='button' to={`${kol3list05.link}`}><span className='text-body sitecolor-text'>{kol3list05.label}</span></Link></li>
                    </ul>
                </div>
                <div className='col-lg-2 pe-0 px-0'>
                    <h5>{heading03}</h5>
                    <hr className='divider-footer sitecolor-primary opacity-100 w-25 mb-3'></hr>
                    <div className='py-2'>
                        <h6 className='mb-2'>{company01.heading}</h6>
                        <p className='small'>{company01.deskripsi}</p>
                    </div>
                    <div className='py-2'>
                        <h6 className='mb-2'>{company02.heading}</h6>
                        <p className='small'>{company02.deskripsi}</p>
                    </div>
                </div>
            </div>
            <hr className='sitecolor-border opacity-100 mb-4'></hr>
            <div className='row row-cols-lg-2 row-cols-1'>
                <div className='d-flex align-items-center col ps-0 pe-lg-4 px-0'>
                    <p className='accent-text text-lg-start text-center sitecolor-primary mb-0'>{creditfooter}</p>
                </div>
                <div className='d-flex align-items-center justify-content-lg-end justify-content-center col ps-lg-4 pe-0 px-0 pt-lg-0 pt-3'>
                    <SocialIcon />
                </div>
            </div>
        </div>
    </div>
  )
}
