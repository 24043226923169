import React from 'react'
import { Helmet } from 'react-helmet'
import Quotation from '../blocks/Quotation';

export default function GetQuotation() {

    document.title = 'Get an Quotation'
    window.scrollTo(0, 0);

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <Quotation />
    </>
  )
}