import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Logo from '../assets/images/logo-wikrea.svg'
import Mobilemenu from '../partials/Mobilemenu'
import Navmenu from '../partials/navmenu/Navmenu'
import SocialIcon from '../subs/SocialIcon';

export default function Header() {

    const [isLoading, setIsLoading] = useState(true);

    // API Header
    const [mainbarbtn, setMainbarBtn] = useState([]);
    const [topbarleft01, setTopbarLeft01] = useState([]);
    const [topbarleft02, setTopbarLeft02] = useState([]);
    const [topbarleft03, setTopbarLeft03] = useState([]);

    useEffect(() => {

        const headerBar = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/2113?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setMainbarBtn(data.acf.main_bar.button_cta);
                    setTopbarLeft01(data.acf.top_bar.left_info.link_1);
                    setTopbarLeft02(data.acf.top_bar.left_info.link_2);
                    setTopbarLeft03(data.acf.top_bar.left_info.link_3);
                    setIsLoading(!isLoading);
                });}
                
        headerBar();
    }, []);
    // End API Header
    
  return (

    <header className='site-header'>

        {/* -- TopBar Header -- */}
       <div className='container-fluid p-0 sitebg-accent'>
            <div className='container-xxl'>
                <div className='row pt-3 pb-5 mb-3'>
                    {/* - Left TopBar - */}
                    <div className='d-lg-flex d-none align-items-center col-lg-6 ps-0 pe-4'>
                        <ul className='hz-list-tb p-0 accent-text mb-0 sitecolor-white-tr'>
                            <li>{isLoading? <span>Loading...</span> : <Link className="sitecolor-white-tr" type="button" to={`${topbarleft01.link}`}>{topbarleft01.label}</Link>}</li>
                            <li>{isLoading? <span>Loading...</span> : <Link className="sitecolor-white-tr" type="button" to={`${topbarleft02.link}`}>{topbarleft02.label}</Link>}</li>
                            <li>{isLoading? <span>Loading...</span> : <Link className="sitecolor-white-tr" type="button" to={`${topbarleft03.link}`}>{topbarleft03.label}</Link>}</li>                            
                        </ul>
                    </div>
                    {/* - Right TopBar - */}
                    <div className='d-flex align-items-center justify-content-lg-end justify-content-center col-lg-6 ps-lg-4 ps-0 pe-0'>
                        <SocialIcon />
                    </div>
                </div>
            </div>
        </div>

        {/* -- MainBar -- */}
        <div className='container-xxl main-bar mt-n5'>
            <div className='container-fluid px-lg-4 py-lg-0 p-3 bg-white'>
                <div className='row m-0'>
                    <div className='d-flex align-items-center col-lg-3 col-8 p-0'>
                        {/* - Main Brand - */}
                        <div className='header-logo'>
                            <Link to='/'><img src={Logo} alt=''></img></Link>
                        </div>
                    </div>
                    {/* - Nav Menu - */}
                    <div className='d-flex align-items-center justify-content-lg-center justify-content-end col-lg-6 col-4 p-0'>
                        <Mobilemenu />
                        <Navmenu />{/* - Navigation Menu - */}
                    </div>
                    {/* - Header CTA - */}
                    <div className='d-flex align-items-center justify-content-end col-lg-3 p-0 mt-lg-0 mt-3'>
                        <a className='btn-one hvr-float btn-full' type='button' href={`${mainbarbtn.link_button}` } target='_blank' rel='noreferrer noopener'>{isLoading? <span>Loading...</span> : <span><i className={`${mainbarbtn.font_awesome} me-2`}></i>{mainbarbtn.label_button}</span>}</a>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}
