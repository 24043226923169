import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

export default function WebdevMenu() {

    // API Menu Webdev
    const [webdev01, setWebdev01] = useState([]);
    const [webdev02, setWebdev02] = useState([]);
    const [webdev03, setWebdev03] = useState([]);
    const [webdev04, setWebdev04] = useState([]);
    const [webdev05, setWebdev05] = useState([]);
    const [webdev06, setWebdev06] = useState([]);
    const [webdev07, setWebdev07] = useState([]);
    const [webdev08, setWebdev08] = useState([]);

    useEffect(() => {

        const menuWebdev = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/19/")
                .then((response) => response.json())
                .then((data) => {
                    setWebdev01(data.acf.webdev_menu_1);
                    setWebdev02(data.acf.webdev_menu_2);
                    setWebdev03(data.acf.webdev_menu_3);
                    setWebdev04(data.acf.webdev_menu_4);
                    setWebdev05(data.acf.webdev_menu_5);
                    setWebdev06(data.acf.webdev_menu_6);
                    setWebdev07(data.acf.webdev_menu_7);
                    setWebdev08(data.acf.webdev_menu_8);
                
                });}
                
        menuWebdev();
    }, []);
    // End API Menu Webdev


  return (
    <>
        <div className='row row-cols-md-2 row-cols-1'>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev01.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev01.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev01.nama_menu}</h6>
                            <p className='mb-0'>{webdev01.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev02.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev02.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev02.nama_menu}</h6>
                            <p className='mb-0'>{webdev02.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev03.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev03.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev03.nama_menu}</h6>
                            <p className='mb-0'>{webdev03.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev04.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev04.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev04.nama_menu}</h6>
                            <p className='mb-0'>{webdev04.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev05.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev05.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev05.nama_menu}</h6>
                            <p className='mb-0'>{webdev05.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev06.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev06.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev06.nama_menu}</h6>
                            <p className='mb-0'>{webdev06.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev07.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev07.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev07.nama_menu}</h6>
                            <p className='mb-0'>{webdev07.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col p-2'>
                <Link className="dropdown-item" type="button" to={`${webdev08.link_menu}`}>
                    <div className='d-flex align-items-center'>
                        <div className='float-start me-2'>
                            <span className={`${webdev08.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                        <div className='float-start'>
                            <h6 className='mb-1'>{webdev08.nama_menu}</h6>
                            <p className='mb-0'>{webdev08.deskripsi_menu}</p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </>
  )
}
