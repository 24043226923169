import React from 'react'
import { Helmet } from 'react-helmet'
import AfterHeader from '../blocks/AfterHeader'
import BusinessNumber from '../blocks/BusinessNumber';
import LayananKami from '../blocks/LayananKami';
import TechnologyUsed from '../blocks/TechnologyUsed';

export default function Services() {

    document.title = 'Our Services'
    window.scrollTo(0, 0);

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <AfterHeader />
        <LayananKami />
        <BusinessNumber />
        <TechnologyUsed />
    </>
  )
}
