import React, {useState, useEffect} from 'react'
import Column from '../utils/Column'

export default function Founder() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [founder, setFounder] = useState([])
    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [deskripsi, setDeskripsi] = useState([])
    const [nama, setNama] = useState([])
    const [jabatan, setJabatan] = useState([])


    useEffect(() => {

        const founderInfo = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2370?acf_format=standard")
            .then((response) => response.json())
            .then((data) => {
                setFounder(data.acf.founder)
                setSubheading(data.acf.subheading)
                setHeading(data.acf.heading)
                setDeskripsi(data.acf.deskripsi)
                setNama(data.acf.founder_info.nama)
                setJabatan(data.acf.founder_info.jabatan)
                setIsLoading(!isLoading)
            })
        }
        
        founderInfo();
    }, []);

  return (
    <>
        <div className='container-fluid p-0 sitebg-accent mt-lg-5'>
            <div className='container-xxl'>
                {isLoading ? <div className="loader-left bg-transparent loader-founder d-flex align-items-center" role="status">
                    <div className='container-xxl ps-0 px-0 pe-lg-5'>
                        <div className='lh-first'></div>
                        <div className='lh-second'></div>
                    </div>
                </div> : 
                <div className='row flex-lg-row flex-column-reverse'>
                    <Column className='d-flex align-items-end' isLeftSecond>
                        <div className='mt-lg-n5 mt-n4'>
                            <img className='mt-lg-n5 founder-img' src={`${founder}`} alt=''></img>
                        </div>
                    </Column>
                    <Column className='ws-tb d-flex align-items-center' isRightSecond>
                        <div>
                            <p className='accent-text sitecolor-primary'>{subheading}</p>
                            <h3 className='text-white'>{parse(`${heading}`)}</h3>
                            <div>{parse(`${deskripsi}`)}</div>
                            <div className='mt-lg-5 mt-3'>
                                <h4 className='text-white mb-2'>{nama}</h4>
                                <p className='accent-text sitecolor-primary mb-0'>{jabatan}</p>
                            </div>
                        </div>
                    </Column>
                </div>}
            </div>
        </div>
    </>
  )
}
