import React from 'react'
import { Helmet } from 'react-helmet'
import Penawaran from '../blocks/Penawaran';

export default function BuatPenawaran() {

    document.title = 'Buat Penawaran'
    window.scrollTo(0, 0);

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <Penawaran />
    </>
  )
}
