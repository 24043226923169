import React, {useState, useEffect} from 'react'
import WebdevMenu from './menu/WebdevMenu'

export default function Webdev() {

  // API Menu Webdev
  const [webdevtitle, setWebdevTitle] = useState([]);

  useEffect(() => {

    const menuWebdev = () => {
      return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/19/")
          .then((response) => response.json())
          .then((data) => {setWebdevTitle(data.acf.title_menu);});}
          
      menuWebdev();
  }, []);
  // End API Menu Webdev

  return (

    <div className="dropdown-menu two p-3">
        <h6 className='ms-4 mt-3 sitecolor-text'>{webdevtitle}</h6>
        <hr className='ms-4 mt-3 sitecolor-border w-50'></hr>
        <WebdevMenu />
    </div>

  )
}
