import React, {useState, useEffect} from 'react'
import PortfolioMenu from './menu/PortfolioMenu'

export default function Portfolio() {

  // API Menu Portfolio
  const [portfoliotitle, setPortfolioTitle] = useState([]);

  useEffect(() => {

    const menuPortfolio = () => {
      return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/79/")
          .then((response) => response.json())
          .then((data) => {setPortfolioTitle(data.acf.title_menu);});}
          
      menuPortfolio();
  }, []);
  // End API Menu Portfolio

  return (
    <div className="dropdown-menu one p-3">
        <h6 className='ms-4 mt-3 sitecolor-text'>{portfoliotitle}</h6>
        <hr className='ms-4 mt-3 sitecolor-border w-75'></hr>
        <PortfolioMenu />
    </div>
  )
}
