import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

export default function LayananKami() {

    const [isLoading, setIsLoading] = useState(true);

    const [gridLayanan, setGridLayanan] = useState([])

    useEffect(() => {

        const portfolioBlock = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/service")
                .then((response) => response.json())
                .then((data) => {
                  setGridLayanan(data)
      
                  setIsLoading(!isLoading)
                });}
                
          portfolioBlock();
      }, []);

  return (
    <>
        <div className='container-xxl ws-tb my-lg-n5'>
            <div className='row gy-lg-5 gy-4 gx-lg-5'>
            {gridLayanan.map((layanan)=>
                <div className='col-lg-4'>
                    {isLoading? <div className='sg-loader w-100'>
                    </div> : 
                    <div className='box hvr-float p-5 br-5 border siteborder-border'>
                        <div className='d-flex justify-content-center mb-4'>
                            <i className={`${layanan.acf.thumbnail.font_awesome} icon-medium sitecolor-primary`}></i>
                        </div>
                        <h5 className='text-center mb-2'>{layanan.acf.thumbnail.judul}</h5>
                        <p className='text-center mb-4'>{layanan.acf.thumbnail.excerpt}</p>
                        <div className='d-flex justify-content-center'>
                            <Link className='btn-under hvr-float' type='button' to={`/services/${layanan.slug}`}>Learn More <i className='fas fa-right-long ms-2'></i></Link>
                        </div>
                    </div>}
                </div>)}
            </div>
        </div>
    </>
  )
}
