import React, {useState, useEffect} from 'react'

export default function ServicesGrid() {

    const [isLoading, setIsLoading] = useState(true);

  const [grid01, setGrid01] = useState ([])
  const [grid02, setGrid02] = useState ([])
  const [grid03, setGrid03] = useState ([])
  const [grid04, setGrid04] = useState ([])

  useEffect(() => {

    const portfolioBlock = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2245?acf_format=standard")
            .then((response) => response.json())
            .then((data) => {
              setGrid01(data.acf.grid_1)
              setGrid02(data.acf.grid_2)
              setGrid03(data.acf.grid_3)
              setGrid04(data.acf.grid_4)
  
              setIsLoading(!isLoading)
            });}
            
      portfolioBlock();
  }, []);

  return (
    <>
        <div className='row row-cols-lg-4 row-cols-md-2 row-cols-1 gx-md-4 gx-0 gy-lg-0 gy-4'>
            <div className='col py-lg-4'>
                {isLoading? <div className='sg-loader w-100'>
                </div> : 
                <div className='box hvr-float p-5 br-5 border siteborder-border'>
                    <div className='d-flex justify-content-center mb-4'>
                        <img className='w-25' src={`${grid01.icon}`} alt=''></img>
                    </div>
                    <h5 className='text-center mb-2'>{grid01.heading}</h5>
                    <p className='text-center mb-0'>{grid01.deskripsi}</p>
                </div>}
            </div>
            <div className='col py-lg-4'>
                {isLoading? <div className='sg-loader w-100'>
                </div> : 
                <div className='box hvr-float p-5 br-5 border siteborder-border'>
                    <div className='d-flex justify-content-center mb-4'>
                        <img className='w-25' src={`${grid02.icon}`} alt=''></img>
                    </div>
                    <h5 className='text-center mb-2'>{grid02.heading}</h5>
                    <p className='text-center mb-0'>{grid02.deskripsi}</p>
                </div>}
            </div>
            <div className='col'>
                {isLoading? <div className='mt-lg-4 mb-lg-4 sg-loader w-100'>
                </div> : 
                <div className='box hvr-float p-5 br-5 sitebg-whitesmoke border siteborder-whitesmoke'>
                    <div className='d-flex justify-content-center mb-4 mt-lg-4'>
                        <img className='w-25' src={`${grid03.icon}`} alt=''></img>
                    </div>
                    <h5 className='text-center mb-2'>{grid03.heading}</h5>
                    <p className='text-center mb-lg-4'>{grid03.deskripsi}</p>
                </div>}
            </div>
            <div className='col py-lg-4'>
                {isLoading? <div className='sg-loader w-100'>
                </div> : 
                <div className='box hvr-float p-5 br-5 border siteborder-border'>
                    <div className='d-flex justify-content-center mb-4'>
                        <img className='w-25' src={`${grid04.icon}`} alt=''></img>
                    </div>
                    <h5 className='text-center mb-2'>{grid04.heading}</h5>
                    <p className='text-center mb-0'>{grid04.deskripsi}</p>
                </div>}
            </div>
        </div>
    </>
  )
}
