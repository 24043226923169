import React, {useState, useEffect} from 'react'

export default function HargaBlock() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [info, setInfo] = useState([])
    const [harga01, setHarga01] = useState([])
    const [harga02, setHarga02] = useState([])
    const [harga03, setHarga03] = useState([])
    const [harga04, setHarga04] = useState([])
    const [harga05, setHarga05] = useState([])
    const [harga06, setHarga06] = useState([])
    const [harga07, setHarga07] = useState([])
    const [harga08, setHarga08] = useState([])
    const [harga09, setHarga09] = useState([])
    const [harga10, setHarga10] = useState([])

    useEffect(() => {

        const HargaLayanan = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2524")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.heading.subheading);
                    setHeading(data.acf.heading.heading);
                    setInfo(data.acf.heading.info);
                    setHarga01(data.acf.harga_1)
                    setHarga02(data.acf.harga_2)
                    setHarga03(data.acf.harga_3)
                    setHarga04(data.acf.harga_4)
                    setHarga05(data.acf.harga_5)
                    setHarga06(data.acf.harga_6)
                    setHarga07(data.acf.harga_7)
                    setHarga08(data.acf.harga_8)
                    setHarga09(data.acf.harga_9)
                    setHarga10(data.acf.harga_10)

                    setIsLoading(!isLoading);
                });}
                
        HargaLayanan();
    }, []);

  return (
    <>
        <div className='container-xxl ws-tb'>
            <div className='container center'>
                <p className='accent-text text-center sitecolor-primary'>{subheading}</p>
                <h3 className='text-center'>{parse(`${heading}`)}</h3>
            </div>
            <div className='container table-fitur mt-lg-4'>
                <table className='table table-hover br-5'>
                    <thead>
                        <th scope='col' className='p-4 w-50 sitebg-accent'><h4 className='mb-0 text-white'>Keterangan Layanan</h4></th>
                        <th scope='col' className='p-4 w-25 sitebg-secondary text-center'><h4 className='mb-0 text-white'>Harga Layanan</h4></th>
                        <th scope='col' className='p-4 w-25 sitebg-primary text-center'><h4 className='mb-0 text-white'>Quantity</h4></th>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{harga01.label}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-secondary'>{harga01.harga}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-primary'>{harga01.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{harga02.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-secondary'>{harga02.harga}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-primary'>{harga02.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{harga03.label}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-secondary'>{harga03.harga}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-primary'>{harga03.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{harga04.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-secondary'>{harga04.harga}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-primary'>{harga04.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{harga05.label}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-secondary'>{harga05.harga}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-primary'>{harga05.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{harga06.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-secondary'>{harga06.harga}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-primary'>{harga06.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{harga07.label}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-secondary'>{harga07.harga}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-primary'>{harga07.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{harga08.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-secondary'>{harga08.harga}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-primary'>{harga08.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 sitebg-whitesmoke'><h6 className='mb-0'>{harga09.label}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-secondary'>{harga09.harga}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-primary'>{harga09.qty}</h6></td>
                        </tr>
                        <tr>
                            <td className='p-4 bg-light'><h6 className='mb-0'>{harga10.label}</h6></td>
                            <td className='text-center p-4 sitebg-whitesmoke'><h6 className='mb-0 text-center sitecolor-secondary'>{harga10.harga}</h6></td>
                            <td className='text-center p-4 bg-light'><h6 className='mb-0 text-center sitecolor-primary'>{harga10.qty}</h6></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='container center mt-4 mb-0'><h6 className='text-danger text-center'>{info}</h6></div>
        </div>
    </>
  )
}
