import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import AfterHeader from '../blocks/AfterHeader'
import DynamicWebsite from '../blocks/DynamicWebsite';
import ServiceQuotation from '../blocks/ServiceQuotation';
import ServicesGrid from '../subs/ServicesGrid';
import FiturWebsite from './blocks/FiturWebsite';
import Introduction from './blocks/Introduction';

export default function WebsiteCustom() {

  const [isLoading, setIsLoading] = useState(true)

  const [titlePage, setTitlePage] = useState([])

  const parse = require('html-react-parser');

  // API Services
  const [subheading, setSubheading] = useState([]);
  const [heading, setHeading] = useState([]);

  const pathname = window.location.pathname.split('/').pop()

    useEffect(() => {

        const servicesPost = () => {
            return fetch(`https://aio.wikrea.co.id/wp-json/wp/v2/service?acf_format=standard&slug=${pathname}`)
                .then((response) => response.json())
                .then((data) => {
                    setTitlePage(data[0].title.rendered);
                    setIsLoading(!isLoading)
                });}
                
        servicesPost();

    const portfolioBlock = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2245?acf_format=standard")
            .then((response) => response.json())
            .then((data) => {
              setSubheading(data.acf.subheading);
              setHeading(data.acf.heading);
  
              setIsLoading(!isLoading)
            });}
            
      portfolioBlock();

    }, []);

    document.title = 'Website Custom'
    window.scrollTo(0, 0);

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan</title>
        </Helmet>
        <AfterHeader />
        <Introduction />
        <DynamicWebsite />
        <FiturWebsite />
        <div className='container-xxl'>
          {isLoading? <div className='container center loader-center'>
            <div className='center-1'></div>
            <div className='center-2'></div>
          </div> : 
          <div className='container center mb-4'>
              <p className='accent-text text-center sitecolor-primary'>{subheading}</p>
              <h3 className='text-center'>{parse(`${heading}`)}</h3>
          </div>}
          <ServicesGrid />
        </div>
        <div className='mt-lg-n0 mt-n5'>
          <ServiceQuotation />
        </div>
    </>
  )
}
