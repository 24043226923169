import React, {useState, useEffect} from 'react'
import Column from '../utils/Column'
import Lottie from'lottie-react'
import LottieDynamic from '../assets/images/dynamic-lottie.json'
import {Link} from 'react-router-dom'

export default function DynamicWebsite() {
    
    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [deskripsi, setDeskripsi] = useState([])
    const [cta, setCta] = useState([])

    

    useEffect(() => {

        const dynamicFitur = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2235?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.subheading);
                    setHeading(data.acf.heading);
                    setDeskripsi(data.acf.deskripsi);
                    setCta(data.acf.cta)
                    setIsLoading(!isLoading);
                });}
                
        dynamicFitur();
    }, []);

  return (
    <>  {isLoading ? <div className="loader-left loader-dynamic d-flex align-items-center" role="status">
            <div className='container-xxl pe-lg-5'>
                <div className='lh-first'></div>
                <div className='lh-second'></div>
                <div className='lh-last'></div>
            </div>
        </div> : 
        <div className='container-fluid p-0 sitebg-whitesmoke mt-lg-n5 mt-n3'>
            <div className='after-header-shape top'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="shape-hero-1" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path className="shape-hero-1" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path className="shape-hero-1" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                </svg>
            </div>
            <div className='after-header-shape bottom'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="shape-hero-1" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path className="shape-hero-1" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path className="shape-hero-1" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                </svg>
            </div>
            <div className='container-xxl mb-5'>
                <div className='row'>
                    <Column isLeftFirst>
                        <div>
                            <Lottie animationData={LottieDynamic} loop={true} autoplay={true} />
                        </div>
                    </Column>
                    <Column className='d-flex align-items-center' isRightFirst>
                        <div>
                            <p className='accent-text sitecolor-primary'>{subheading}</p>
                            <h3>{parse(`${heading}`)}</h3>
                            <p>{deskripsi}</p>
                            <Link className='btn-one hvr-float' type='button' to={`${cta.link}`}><i className={`${cta.font_awesome} me-2`}></i>{cta.label}</Link>
                        </div>
                    </Column>
                </div>
            </div>
        </div>}
    </>
  )
}
