import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import CounterBusiness from '../subs/CounterBusiness';
import Column from '../utils/Column'

export default function Business() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [quote, setQuote] = useState([])
    const [deskripsi, setDeskripsi] = useState([])
    const [cta, setCta] = useState([])

    useEffect(() => {
        const businessH = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2379?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.subheading);
                    setHeading(data.acf.heading);
                    setQuote(data.acf.quote)
                    setDeskripsi(data.acf.deskripsi);
                    setCta(data.acf.cta)
                    setIsLoading(!isLoading)
                });}
                
        businessH();
    }, []);

  return (
    <>  
        <div className='container-xxl ws-tb'>
            {isLoading ? <div className="loader-left bg-transparent loader-dynamic d-flex align-items-center" role="status">
                <div className='container-xxl mt-n5 ps-0 px-0 pe-lg-5'>
                    <div className='lh-first'></div>
                    <div className='lh-second'></div>
                    <div className='lh-last'></div>
                </div>
            </div> : 
            <div className='row'>
                <Column isLeftFirst>
                    <div>
                        <p className='accent-text sitecolor-primary'>{subheading}</p>
                        <h3>{heading}</h3>
                        <div className='p-4 sitebg-whitesmoke quote-block br-5 my-4'>
                            <p className='h5 mb-0 sitecolor-text fst-italic'>{quote}</p>
                        </div>
                        <Link className='btn-one hvr-float' type='button' to={`${cta.link}`}>{cta.label}</Link>
                    </div>
                </Column>
                <Column isRightFirst>
                    <div>{parse(`${deskripsi}`)}</div>
                </Column>
            </div>}
            <div className='mt-5'>
                <CounterBusiness />
            </div>
        </div>
    </>
  )
}
