import React, {useState, useEffect} from 'react'

export default function PortfolioMenu() {
    const [isLoading, setIsLoading] = useState(true);

    // API Menu Portfolio
    const [portfolio01, setPortfolio01] = useState([]);
    const [portfolio02, setPortfolio02] = useState([]);
    
    useEffect(() => {

        const menuPortfolio = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/79/")
                .then((response) => response.json())
                .then((data) => {
                    setPortfolio01(data.acf.portfolio_menu_1);
                    setPortfolio02(data.acf.portfolio_menu_2);
                    setIsLoading(!isLoading);
                });}

                
        menuPortfolio();
    }, []);
    // End API Menu Portfolio

  return (
    <div className='row row-cols-1'>
        <div className='col p-2'>
            <a className="dropdown-item" type="button" href={`${portfolio01.link_menu}`} target='_blank' rel='noreferrer noopener'>
                <div className='d-flex align-items-center'>
                    <div className='float-start envato-icon me-3'>
                    {isLoading ? <div className="spinner-border sitecolor-whitesmoke" role="status"><span className="sr-only">Loading...</span></div> : <img className='w-100' src={`${portfolio01.image_icon}`} alt=''></img>}
                    </div>
                    <div className='float-start'>
                        <h6 className='mb-1'>{portfolio01.nama_menu}</h6>
                        <p className='mb-0'>{portfolio01.deskripsi_menu}</p>
                    </div>
                </div>
            </a>
        </div>
        <div className='col p-2'>
            <a className="dropdown-item" type="button" href={`${portfolio02.link_menu}`} target='_blank' rel='noreferrer noopener'>
                <div className='d-flex align-items-center'>
                    <div className='float-start envato-icon me-3'>
                    {isLoading ? <div className="spinner-border sitecolor-whitesmoke" role="status"><span className="sr-only">Loading...</span></div> : <img className='w-100' src={`${portfolio02.image_icon}`} alt=''></img>}
                    </div>
                    <div className='float-start'>
                        <h6 className='mb-1'>{portfolio02.nama_menu}</h6>
                        <p className='mb-0'>{portfolio02.deskripsi_menu}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
  )
}
