import React from 'react'

export default function Column(props) {
    const className = [props.className];
    if (props.isLeftFirst) className.push("col-lg-6 ps-0 pe-lg-4 px-0")
    if (props.isRightFirst) className.push("col-lg-6 ps-lg-4 pe-0 pt-lg-0 px-0 pt-4")
    if (props.isLeftSecond) className.push("col-lg-6 ps-0 pe-lg-4 px-0 pt-lg-0 pt-4")
    if (props.isRightSecond) className.push("col-lg-6 ps-lg-4 pe-0 px-0")

  return (
    <div className={className.join(" ")}>
        {props.children}
    </div>
  )
}
