import React from 'react'
import { Helmet } from 'react-helmet';
import AfterHeader from '../blocks/AfterHeader';
import HargaBlock from '../blocks/HargaBlock';

export default function Harga() {

    document.title = 'Harga Layanan'
   window.scrollTo(0, 0);
  //

  return (
    <>
        <Helmet>
            <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <AfterHeader />
        <HargaBlock />
    </>
  )
}
