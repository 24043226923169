import React, {useState, useEffect} from 'react'
import PortfolioImageSlide from '../subs/PortfolioImageSlide'

export default function PortfolioSlider() {

  const [isLoading, setIsLoading] = useState(true);

  const parse = require('html-react-parser');

  // API Portfolio
  const [subheading, setSubheading] = useState([]);
  const [heading, setHeading] = useState([]);

  useEffect(() => {

    const portfolioBlock = () => {
      return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2213?acf_format=standard")
          .then((response) => response.json())
          .then((data) => {
            setSubheading(data.acf.subheading);
            setHeading(data.acf.heading);

            setIsLoading(!isLoading)
          });}
          
      portfolioBlock();
  }, []);
  //

  return (
    <div className='container-xxl ws-tb'>
        {isLoading? <div className='container center loader-center'>
          <div className='center-1'></div>
          <div className='center-2'></div>
        </div> : 
        <div className='container center'>
            <p className='accent-text sitecolor-primary text-center'>{subheading}</p>
            <h3 className='text-center'>{parse(`${heading}`)}</h3>
        </div>}
        <PortfolioImageSlide />
    </div>
  )
}
