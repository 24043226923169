import React, {useState, useEffect} from 'react'
import Column from '../utils/Column'
import { Link } from 'react-router-dom'

export default function FiturUtama() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [deskripsi, setDeskripsi] = useState([])
    const [grid01, setGrid01] = useState ([])
    const [grid02, setGrid02] = useState ([])
    const [grid03, setGrid03] = useState ([])
    const [grid04, setGrid04] = useState ([])
    const [cta, setCta] = useState([])    

    useEffect(() => {

        const gridFitur = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2278?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.subheading);
                    setHeading(data.acf.heading);
                    setDeskripsi(data.acf.deskripsi);
                    setGrid01(data.acf.grid_1)
                    setGrid02(data.acf.grid_2)
                    setGrid03(data.acf.grid_3)
                    setGrid04(data.acf.grid_4)
                    setCta(data.acf.cta)
                    setIsLoading(!isLoading);
                });}

        gridFitur();
    }, []);

  return (
    <>
        {isLoading ? <div className="loader-left loader-dynamic d-flex align-items-center" role="status">
            <div className='container-xxl pe-lg-5'>
                <div className='lh-first'></div>
                <div className='lh-second'></div>
                <div className='lh-last'></div>
        </div>
        </div> : 
        <div className='container-xxl ws-tb'>
            <div className='row flex-lg-row flex-column-reverse'>
                <Column isLeftSecond>
                    <div className='row row-cols-md-2 row-cols-1'>
                        <div className='col ps-0 pe-md-3 px-0'>
                            <div className='p-4 sitebg-accent br-5 hvr-float'>
                                <h4 className='sitecolor-primary mb-3'>{grid01.nomor}</h4>
                                <h5 className='text-white mb-2'>{grid01.heading}</h5>
                                <p className='sitecolor-white-tr mb-0'>{grid01.deskripsi}</p>
                            </div> 
                        </div>
                        <div className='col pt-md-3 ps-md-3 pe-0 px-0 pt-md-0 pt-4'>
                            <div className='p-4 sitebg-whitesmoke br-5 box-hvr-accent hvr-float'>
                                <h4 className='sitecolor-primary mb-3'>{grid02.nomor}</h4>
                                <h5 className='mb-2'>{grid02.heading}</h5>
                                <p className='mb-0'>{grid02.deskripsi}</p>
                            </div>
                        </div>
                        <div className='col ps-0 pe-md-3 pt-md-3 px-0 pt-4'>
                            <div className='p-4 sitebg-whitesmoke br-5 box-hvr-accent hvr-float'>
                                <h4 className='sitecolor-primary mb-3'>{grid03.nomor}</h4>
                                <h5 className='mb-2'>{grid03.heading}</h5>
                                <p className='mb-0'>{grid03.deskripsi}</p>
                            </div> 
                        </div>
                        <div className='col pt-md-4 ps-md-3 pe-0 px-0 pt-4'>
                            <div className='p-4 mt-md-2 sitebg-accent br-5 hvr-float'>
                                <h4 className='sitecolor-primary mb-3'>{grid04.nomor}</h4>
                                <h5 className='text-white mb-2'>{grid04.heading}</h5>
                                <p className='sitecolor-white-tr mb-0'>{grid04.deskripsi}</p>
                            </div>
                        </div>
                    </div>
                </Column>
                <Column className='d-flex align-items-center' isRightSecond>
                    <div>
                        <p className='accent-text sitecolor-primary'>{subheading}</p>
                        <h3>{parse(`${heading}`)}</h3>
                        <div>{parse(`${deskripsi}`)}</div>
                        <Link className='btn-one hvr-float' type='button' to={`${cta.link}`}>{cta.label}<i className={`${cta.font_awesome} ms-2`}></i></Link>
                    </div>
                </Column>
            </div>
        </div> }
    </>
  )
}
