import React, {useState, useEffect} from 'react'

export default function SocialIcon() {

    const [isLoading, setIsLoading] = useState(true);

    // API Social Icon
    const [social01, setSocial01] = useState([]);
    const [social02, setSocial02] = useState([]);
    const [social03, setSocial03] = useState([]);

    useEffect(() => {
        const socialInfo = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/2132")
                .then((response) => response.json())
                .then((data) => {
                    setSocial01(data.acf.social_1);
                    setSocial02(data.acf.social_2);
                    setSocial03(data.acf.social_3);
                    setIsLoading(!isLoading);
                    
                });}
        socialInfo();
    }, []);
    // End API Social Icon

  return (
    <ul className='hz-list-social p-0 mb-0'>
        <li>{isLoading ?<div className='px-2'> <div className="spinner-border spinner-border-sm sitecolor-whitesmoke" role="status"><span className="sr-only">Loading...</span></div></div> : <a className='p-0 hvr-float' type='button' href={`${social01.link}`} target='_blank' rel='noreferrer noopener'><span className={`${social01.font_awesome} i-stack-small sitebg-primary text-white`}></span></a>}</li>
        <li>{isLoading ? <div className='px-2'> <div className="spinner-border spinner-border-sm sitecolor-whitesmoke" role="status"><span className="sr-only">Loading...</span></div></div> : <a className='p-0 hvr-float' type='button' href={`${social02.link}`} target='_blank' rel='noreferrer noopener'><span className={`${social02.font_awesome} i-stack-small sitebg-primary text-white`}></span></a>}</li>
        <li>{isLoading ? <div className='px-2'> <div className="spinner-border spinner-border-sm sitecolor-whitesmoke" role="status"><span className="sr-only">Loading...</span></div></div> : <a className='p-0 hvr-float' type='button' href={`${social03.link}`} target='_blank' rel='noreferrer noopener'><span className={`${social03.font_awesome} i-stack-small sitebg-primary text-white`}></span></a>}</li>
    </ul>
  )
}
