import React, {useState, useEffect} from 'react'

export default function FiturFirst() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [grid01, setGrid01] = useState ([])
    const [grid02, setGrid02] = useState ([])
    const [grid03, setGrid03] = useState ([])
    const [grid04, setGrid04] = useState ([])

    useEffect(() => {

        const gridFitur = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2278?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.subheading);
                    setHeading(data.acf.heading);
                    setGrid01(data.acf.grid_1)
                    setGrid02(data.acf.grid_2)
                    setGrid03(data.acf.grid_3)
                    setGrid04(data.acf.grid_4)

                    setIsLoading(!isLoading)
                });}

        gridFitur();
    }, []);

  return (
    <>
        <div className='container-xxl ws-tb'>
            <div className='container center'>
                <p className='accent-text sitecolor-primary text-center'>{subheading}</p>
                <h3 className='text-center'>{parse(`${heading}`)}</h3>
            </div>
            <div className='row row-cols-lg-4 row-cols-md-2 row-cols-1 gx-md-4 gy-4'>
                <div className='col'>
                    {isLoading? <div className='fitur-loader w-100'>
                    </div> : 
                    <div className='p-4 sitebg-accent hvr-float br-5'>
                        <h4 className='sitecolor-primary mb-3 text-center'>{grid01.nomor}</h4>
                        <h5 className='text-center text-white mb-2'>{grid01.heading}</h5>
                        <p className='text-center sitecolor-white-tr mb-0'>{grid01.deskripsi}</p>
                    </div>}
                </div>
                <div className='col'>
                    {isLoading? <div className='fitur-loader w-100'>
                    </div> : 
                    <div className='p-4 sitebg-whitesmoke box-hvr-accent hvr-float br-5'>
                        <h4 className='sitecolor-primary mb-3 text-center'>{grid02.nomor}</h4>
                        <h5 className='text-center mb-2'>{grid02.heading}</h5>
                        <p className='text-center mb-0'>{grid02.deskripsi}</p>
                    </div>}
                </div>
                <div className='col'>
                    {isLoading? <div className='fitur-loader w-100'>
                    </div> : 
                    <div className='p-4 sitebg-accent hvr-float br-5'>
                        <h4 className='sitecolor-primary mb-3 text-center'>{grid03.nomor}</h4>
                        <h5 className='text-center text-white mb-2'>{grid03.heading}</h5>
                        <p className='text-center sitecolor-white-tr mb-0'>{grid03.deskripsi}</p>
                    </div>}
                </div>
                <div className='col'>
                    {isLoading? <div className='fitur-loader w-100'>
                    </div> : 
                    <div className='p-4 sitebg-whitesmoke box-hvr-accent hvr-float br-5'>
                        <h4 className='sitecolor-primary mb-3 text-center'>{grid04.nomor}</h4>
                        <h5 className='text-center mb-2'>{grid04.heading}</h5>
                        <p className='text-center mb-0'>{grid04.deskripsi}</p>
                    </div>}
                </div>
            </div>
        </div>
    </>
  )
}
