import React from 'react'
import FormTrial from './FormTrial'

export default function Trial() {
  return (
    <div className='container-xxl ws-tb mt-lg-0 mt-4'>
        <div className='container center mt-5 px-0'>
            <div className='of-hidden p-lg-5 p-4 br-5 sitebg-whitesmoke mt-lg-0'>
                <div className='form-head py-4 px-lg-5 px-4 mb-3 sitebg-primary'>
                    <h4 className='mb-0 text-white px-lg-0 px-2'>Dapatkan Situs Demo Untuk Percobaan</h4>
                </div>
                <div className='mt-5 pt-lg-3 pt-5'>
                    <div className='pt-lg-0 pt-3'>
                        <FormTrial />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
