import React, {useState} from 'react'

export default function FormContact() {

  const [message, setMessage] = useState([]);
  const [isSent, setSent] = useState(true)
  const [namaDepan, setNamaDepan] = useState('');
  const [namaBelakang, setNamaBelakang] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [pesan, setPesan] = useState('');

  const emailContact = {
   "nama_depan": namaDepan,
   "nama_belakang": namaBelakang,
   "email" : email,
   "subject" : subject,
   "pesan" : pesan,
   "asssAsoosppwirhgg" : "checked"
   };

  var formdata = new FormData();
  for (const field in emailContact) {
   formdata.append(field, emailContact[field]);}

   var requestOptions = {
      method: 'POST',
      body: formdata,
    };

  
  // ...
  const addFormTo = async (subs) => {
     await fetch('https://aio.wikrea.co.id/wp-json/contact-form-7/v1/contact-forms/2388/feedback', requestOptions)
        .then((response) => response.json())
        .then((data) => {
           setNamaDepan('');
           setNamaBelakang('')
           setEmail('')
           setSubject('')
           setPesan('')
           setMessage(data.message)

           setSent(!isSent)
        })
        .catch((err) => {
           console.log(err.message);
        });
  };
  
  const clickForm = (e) => {
     e.preventDefault();
     addFormTo();
  };    
  
  return (
     <>
        <form onSubmit={clickForm}>
            <div className='row gx-md-4 gy-4 mt-n4'>
                <div className='col-md-6'>
                    <label htmlFor="namadepan" className="form-label h6 mb-2">Nama Depan</label>
                    <input type="text" className="form-control" id="namadepan" placeholder='cth. John' 
                        value={namaDepan}
                        onChange={(e) => setNamaDepan(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="namabelakang" className="form-label h6 mb-2">Nama Belakang</label>
                    <input type="text" className="form-control" id="namabelakang" placeholder='cth. Doe' 
                        value={namaBelakang}
                        onChange={(e) => setNamaBelakang(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="emailaddress" className="form-label h6 mb-2">Email Address</label>
                    <input type="email" className="form-control" id="emailaddress" placeholder='cth. hello@company.co' 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="subject" className="form-label h6 mb-2">Subject</label>
                    <input type="text" className="form-control" id="subject" placeholder='cth. Konsultasi' 
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required />
                </div>
                <div className='col-12'>
                    <label htmlFor="pesan" className="form-label h6 mb-2">Pesan</label>
                    <textarea className="form-control" rows='4' id="pesan" placeholder='Tulis pesan disini...' 
                        value={pesan}
                        onChange={(e) => setPesan(e.target.value)}
                        required />
                </div>
                <div className='col-12'>
                    <button className='btn-one' type="submit">Kirim Pesan</button>
                </div>
                { isSent? <div className='p-0 mt-0'></div> : <div class="alert alert-info alert-dismissible" role="alert">
                    <div><h6 className='mb-0'>{message}</h6></div>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
            </div>
        </form>
     </>
  );
}
