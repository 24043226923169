import React, {useState, useEffect} from 'react'
import { useMediaQuery } from "react-responsive";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'

export default function TechnologyUsed() {

    const [isLoading, setIsLoading] = useState(true);

    const [techgambar, setTechgambar] = useState([]);

    useEffect(() => {

        const portfolioBlock = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2329?acf_format=standard")
                .then((response) => response.json())
                .then((data) => {
                    setTechgambar(data.acf);
                    setIsLoading(!isLoading)
    
                });}
                
        portfolioBlock();
    }, []);
    //

    const isDesktop = useMediaQuery({
        query: "(min-device-width: 992px)",
      });
      const isTablet = useMediaQuery({ query: "(min-device-width: 768px)" });
      const slideCount = isDesktop ? 4 : isTablet ? 3 : 2 ;
      const heightCount = isDesktop ? 25 : isTablet ? 50 : 50 ;

  return (
    <div className='container ws-b'>
        <CarouselProvider infinite={true} playDirection={'forward'} visibleSlides={slideCount} totalSlides={6} interval={2500} isPlaying={true} naturalSlideWidth={100} naturalSlideHeight={heightCount}>
            <Slider>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : 
                    <div className='d-flex justify-content-center'>
                        <img className='w-25 tech-logo' src={`${techgambar.gambar_1}`} alt=''></img>
                    </div>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : 
                    <div className='d-flex justify-content-center'>
                        <img className='w-25 tech-logo' src={`${techgambar.gambar_2}`} alt=''></img>
                    </div>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : 
                    <div className='d-flex justify-content-center'>
                        <img className='w-25 tech-logo' src={`${techgambar.gambar_3}`} alt=''></img>
                    </div>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : 
                    <div className='d-flex justify-content-center'>
                        <img className='w-25 tech-logo' src={`${techgambar.gambar_4}`} alt=''></img>
                    </div>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : 
                    <div className='d-flex justify-content-center'>
                        <img className='w-25 tech-logo' src={`${techgambar.gambar_5}`} alt=''></img>
                    </div>}
                </Slide>
                <Slide>
                    { isLoading? <div className='loader-custom w-100 h-100'></div> : 
                    <div className='d-flex justify-content-center'>
                        <img className='w-25 tech-logo' src={`${techgambar.gambar_6}`} alt=''></img>
                    </div>}
                </Slide>
            </Slider>
        </CarouselProvider>
    </div>
  )
}
