import React from 'react'
import { Helmet } from 'react-helmet'
import Trial from '../blocks/Trial';

export default function FreeTrial() {

    document.title = 'Free Trial'
    window.scrollTo(0, 0);

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <Trial />
    </>
  )
}
