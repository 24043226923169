import React, {useState, useEffect} from 'react'

export default function IconAbout() {

    const [isLoading, setIsLoading] = useState(true);

    // API About Icon
    const [info01, setInfo01] = useState([]);
    const [info02, setInfo02] = useState([]);
    const [info03, setInfo03] = useState([]);
    const [info04, setInfo04] = useState([]);

    useEffect(() => {

        const headerBar = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2352")
                .then((response) => response.json())
                .then((data) => {
                    setInfo01(data.acf.info_1);
                    setInfo02(data.acf.info_2);
                    setInfo03(data.acf.info_3);
                    setInfo04(data.acf.info_4);
    
                    setIsLoading(!isLoading);
                });}
                
        headerBar();
    }, []);
    // End API

  return (
    <>
        <div className='container-xxl ws-tb'>
            <div className='row row-cols-lg-4 row-cols-md-2 row-cols-1 gx-md-4 gy-lg-0 gy-4 gx-0'>
                <div className='col'>
                    {isLoading? <div className='sg-loader w-100'>
                    </div> : 
                    <div className='box p-5 br-5 border siteborder-border'>
                        <div className='d-flex justify-content-center mb-4'>
                            <i className={`${info01.font_awesome} icon-big sitecolor-primary`}></i>
                        </div>
                        <h5 className='text-center mb-2'>{info01.heading}</h5>
                        <p className='text-center mb-0'>{info01.deskripsi}</p>
                    </div>}
                </div>
                <div className='col'>
                    {isLoading? <div className='sg-loader w-100'>
                    </div> : 
                    <div className='box p-5 br-5 border siteborder-border'>
                        <div className='d-flex justify-content-center mb-4'>
                            <i className={`${info02.font_awesome} icon-big sitecolor-primary`}></i>
                        </div>
                        <h5 className='text-center mb-2'>{info02.heading}</h5>
                        <p className='text-center mb-0'>{info02.deskripsi}</p>
                    </div>}
                </div>
                <div className='col'>
                    {isLoading? <div className='sg-loader w-100'>
                    </div> : 
                    <div className='box p-5 br-5 border siteborder-border'>
                        <div className='d-flex justify-content-center mb-4'>
                            <i className={`${info03.font_awesome} icon-big sitecolor-primary`}></i>
                        </div>
                        <h5 className='text-center mb-2'>{info03.heading}</h5>
                        <p className='text-center mb-0'>{info03.deskripsi}</p>
                    </div>}
                </div>
                <div className='col'>
                    {isLoading? <div className='sg-loader w-100'>
                    </div> : 
                    <div className='box p-5 br-5 border siteborder-border'>
                        <div className='d-flex justify-content-center mb-4'>
                            <i className={`${info04.font_awesome} icon-big sitecolor-primary`}></i>
                        </div>
                        <h5 className='text-center mb-2'>{info04.heading}</h5>
                        <p className='text-center mb-0'>{info04.deskripsi}</p>
                    </div>}
                </div>
            </div>
        </div>
    </>
  )
}
