import React from 'react'
import { Helmet } from 'react-helmet'
import AfterHeader from '../blocks/AfterHeader'
import Business from '../blocks/Business';
import Founder from '../blocks/Founder';
import IconAbout from '../blocks/IconAbout';

export default function About() {

  document.title = 'About Us'
   window.scrollTo(0, 0);
  //

  return (
    <>
      <Helmet>
        <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
      </Helmet>
      <AfterHeader />
      <IconAbout />
      <Founder />
      <Business />
    </>
  )
}
