import React, {useState, useEffect} from 'react'

export default function FormTrial(props) {

    const [select01, setSelect01] = useState([])
    const [select02, setSelect02] = useState([])

    useEffect(() => {

        const industriSelect = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/2411")
                .then((response) => response.json())
                .then((data) => {
                    setSelect01(data.acf);
                });}
                
        industriSelect();

        const skalaSelect = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/2419")
                .then((response) => response.json())
                .then((data) => {
                    setSelect02(data.acf);
                });}
                
        skalaSelect();
    }, []);

    const [message, setMessage] = useState([]);
  const [isSent, setSent] = useState(true)
  const [namaLengkap, setNamaLengkap] = useState('');
  const [namaUsaha, setNamaUsaha] = useState('');
  const [alamatDomisili, setAlamatDomisili] = useState('')
  const [skalaUsaha, setSkalaUsaha] = useState('')
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('')
  const [industri, setIndustri] = useState('')

  const formTrial = {
   "nama_lengkap": namaLengkap,
    "nama_usaha" : namaUsaha,
    "alamat_domisili" : alamatDomisili,
    "skala_usaha" : skalaUsaha,
    "email" : email,
    "telephone" : telephone,
    "industri_usaha" : industri,
   "ashfsfgouag" : "checked"
   };

  var formdata = new FormData();
  for (const field in formTrial) {
   formdata.append(field, formTrial[field]);}

   var requestOptions = {
      method: 'POST',
      body: formdata,
    };

  
  // ...
  const addFormTo = async (subs) => {
     await fetch('https://aio.wikrea.co.id/wp-json/contact-form-7/v1/contact-forms/2410/feedback', requestOptions)
        .then((response) => response.json())
        .then((data) => {
           setNamaLengkap('');
           setNamaUsaha('')
           setAlamatDomisili('')
           setSkalaUsaha('')
           setEmail('')
           setTelephone('')
           setIndustri('')
           setMessage(data.message)

           setSent(!isSent)
        })
        .catch((err) => {
           console.log(err.message);
        });
  };
  
  const clickForm = (e) => {
     e.preventDefault();
     addFormTo();
     console.log(skalaUsaha)
  };

  return (
    <>
        <form onSubmit={clickForm}>
            <div className='row gx-md-4 gy-4 mt-n4'>
                <div className='col-md-6'>
                    <label htmlFor="namalengkap" className="form-label h6 mb-2">Nama Lengkap</label>
                    <input type="text" className="form-control" id="namalengkap" placeholder='cth. John Doe' 
                        value={namaLengkap}
                        onChange={(e) => setNamaLengkap(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="namausaha" className="form-label h6 mb-2">Nama Usaha</label>
                    <input type="text" className="form-control" id="namausaha" placeholder='cth. PT Maju' 
                        value={namaUsaha}
                        onChange={(e) => setNamaUsaha(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="domisili" className="form-label h6 mb-2">Alamat Domisili</label>
                    <input type="text" className="form-control" id="domisili" placeholder='cth. Kota Hidup' 
                        value={alamatDomisili}
                        onChange={(e) => setAlamatDomisili(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="skalausaha" className="form-label h6 mb-2">Skala Usaha</label>
                    <select className="form-select" id="skalausaha" defaultValue={skalaUsaha}
                        onChange={(e) => setSkalaUsaha(e.target.value)}
                        required >
                            <option value='' selected disabled> -- Skala Usaha -- </option>
                            <option value={select02.select_1}>{select02.select_1}</option>
                            <option value={select02.select_2}>{select02.select_2}</option>
                            <option value={select02.select_3}>{select02.select_3}</option>
                            <option value={select02.select_4}>{select02.select_4}</option>
                            <option value={select02.select_5}>{select02.select_5}</option>
                    </select>
                </div>
                <div className='col-md-6'>
                    <label htmlFor="emailaddress" className="form-label h6 mb-2">Email Address</label>
                    <input type="email" className="form-control" id="emailaddress" placeholder='cth. hello@company.co' 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required />
                </div>
                <div className='col-md-6'>
                    <label htmlFor="telephone" className="form-label h6 mb-2">Telephone</label>
                    <input type="tel" className="form-control" id="telephone" placeholder='cth. 085222xxx' 
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        required />
                </div>
                <div className='col-12'>
                    <label htmlFor="industri" className="form-label h6 mb-2">Industri Usaha</label>
                    <select className="form-select" id="industri" defaultValue={industri}
                        onChange={(e) => setIndustri(e.target.value)}
                        required >
                            <option value='' selected disabled> -- Pilih Industri Usaha -- </option>
                            <option value={select01.select_1}>{select01.select_1}</option>
                            <option value={select01.select_2}>{select01.select_2}</option>
                            <option value={select01.select_3}>{select01.select_3}</option>
                            <option value={select01.select_4}>{select01.select_4}</option>
                            <option value={select01.select_5}>{select01.select_5}</option>
                            <option value={select01.select_6}>{select01.select_6}</option>
                            <option value={select01.select_7}>{select01.select_7}</option>
                    </select>
                </div>
                <div className='col-12'>
                    <button className='btn-one' type="submit">Kirim Permintaan</button>
                </div>
                { isSent? <div className='p-0 mt-0'></div> : <div class="alert alert-info alert-dismissible" role="alert">
                    <div><h6 className='mb-0'>{message}</h6></div>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
            </div>
        </form>
    </>
  )
}
