import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

export default function FooterCta() {

  // API Footer Menu
  const [footercta, setFootercta] = useState([]);


  useEffect(() => {

    const footerCta = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/2143?acf_format=standard")
            .then((response) => response.json())
            .then((data) => {
                setFootercta(data.acf.footer_cta);
            });}
            
      footerCta();
  }, []);
  // End API Footer Menu

  return (
    <>
        <Link className='p-0 w-100 footer-btn' type='button' to={`${footercta.link}`}>
            <div className='container-fluid p-0 sitebg-accent footer-cta'>
                <div className='container-xxl ws-tb'>
                    <h1 className='text-center text-white mb-0'>{footercta.label}</h1>
                </div>
            </div>
        </Link>
    </>
  )
}
