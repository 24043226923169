import React, {useState, useEffect} from 'react'
import Column from '../utils/Column'
import FormContact from './FormContact'

export default function ContactInfo() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    // API
    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [deskripsi, setDeskripsi] = useState([])
    const [info01, setInfo01] =useState([])
    const [info02, setInfo02] =useState([])
    const [info03, setInfo03] =useState([])
    const [info04, setInfo04] =useState([])

    useEffect(() => {
        const socialInfo = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2389")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.subheading);
                    setHeading(data.acf.heading);
                    setDeskripsi(data.acf.deskripsi);
                    setInfo01(data.acf.info_1)
                    setInfo02(data.acf.info_2)
                    setInfo03(data.acf.info_3)
                    setInfo04(data.acf.info_4)

                    setIsLoading(!isLoading)
                    
                });}
        socialInfo();
    }, []);

  return (
    <>
        <div className='container-xxl ws-tb'>
            <div className='row'>
                {isLoading? <div className='left-load-only'>
                    <div className='loader-left hero-loader'>
                        <div>
                            <div className='lh-first'></div>
                            <div className='lh-second'></div>
                            <div className='lh-last'></div>
                            <div className='lh-last-2'></div>
                        </div>
                    </div>
                </div> :
                <Column isLeftFirst>
                    <p className='accent-text sitecolor-primary'>{subheading}</p>
                    <h3>{parse(`${heading}`)}</h3>
                    <p>{parse(`${deskripsi}`)}</p>
                    <div>
                        <div>
                            <div className='float-md-start pe-lg-3 pb-3'>
                                <span className={`${info01.font_awesome} i-stack-medium sitebg-primary text-white`}></span>
                            </div>
                            <div className='float-md-start'>
                                <h5 className='mb-1'>{info01.heading}</h5>
                                <p className='mb-0 small'>{info01.deskripsi}</p>
                            </div>
                        </div>
                        <hr className='w-100 border-0'></hr>
                        <div>
                            <div className='float-md-start pe-lg-3 pb-3'>
                                <span className={`${info02.font_awesome} i-stack-medium sitebg-primary text-white`}></span>
                            </div>
                            <div className='float-md-start'>
                                <h5 className='mb-1'>{info02.heading}</h5>
                                <p className='mb-0 small'>{info02.deskripsi}</p>
                            </div>
                        </div>
                        <hr className='w-100 border-0'></hr>
                        <div>
                            <div className='float-md-start pe-lg-3 pb-3'>
                                <span className={`${info03.font_awesome} i-stack-medium sitebg-primary text-white`}></span>
                            </div>
                            <div className='float-md-start'>
                                <h5 className='mb-1'>{info03.heading}</h5>
                                <p className='mb-0 small'>{info03.deskripsi}</p>
                            </div>
                        </div>
                        <hr className='w-100 border-0'></hr>
                        <div>
                            <div className='float-md-start pe-lg-3 pb-3'>
                                <span className={`${info04.font_awesome} i-stack-medium sitebg-primary text-white`}></span>
                            </div>
                            <div className='float-md-start'>
                                <h5 className='mb-1'>{info04.heading}</h5>
                                <p className='mb-0 small'>{info04.deskripsi}</p>
                            </div>
                        </div>
                    </div>
                </Column>}
                <Column isRightFirst>
                    <div className='d-flex align-items-center sitebg-whitesmoke br-5 p-md-5 p-4'>
                        <FormContact />
                    </div>
                </Column>
            </div>
        </div>
    </>
  )
}
