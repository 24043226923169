import React, {useState, useEffect} from 'react'
import CountUp from 'react-countup';

export default function CounterBusiness() {

    const [isLoading, setIsLoading] = useState(true);

    // API Counter
  const [stats01, setStats01] = useState([]);
  const [stats02, setStats02] = useState([]);
  const [stats03, setStats03] = useState([]);
  const [stats04, setStats04] = useState([]);

  useEffect(() => {

    const numberStats = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2313")
            .then((response) => response.json())
            .then((data) => {
              setStats01(data.acf.stats_1);
              setStats02(data.acf.stats_2);
              setStats03(data.acf.stats_3);
              setStats04(data.acf.stats_4);
              setIsLoading(!isLoading)
            });}
            
      numberStats();
  }, []);
  //

  return (
    <div className='row row-cols-lg-4 row-cols-md-2 row-cols-1 gx-lg-4 gx-md-3 gx-0 gy-lg-0 gy-3'>
        <div className='col'>
            {isLoading? <div className='cg-loader w-100'>
                </div> : 
            <div className='p-4 br-5 hvr-shadow'>
                <h3 className='text-center sitecolor-primary mb-2'><CountUp end={stats01.number} duration={5} separator=',' suffix='+' useEasing={true} enableScrollSpy={true}></CountUp></h3>
                <h6 className='text-center mb-0'>{stats01.heading}</h6>
            </div>}
        </div>
        <div className='col'>
            {isLoading? <div className='cg-loader w-100'>
                </div> : 
            <div className='p-4 sitebg-accent br-5 hvr-shadow'>
                <h3 className='text-center sitecolor-primary mb-2'><CountUp end={stats02.number} duration={5} separator=',' suffix='+' useEasing={true} enableScrollSpy={true}></CountUp></h3>
                <h6 className='text-center mb-0 text-white'>{stats02.heading}</h6>
            </div>}
        </div>
        <div className='col'>
            {isLoading? <div className='cg-loader w-100'>
                </div> : 
            <div className='p-4 br-5 hvr-shadow'>
                <h3 className='text-center sitecolor-primary mb-2'><CountUp end={stats03.number} duration={5} separator=',' suffix='+' useEasing={true} enableScrollSpy={true}></CountUp></h3>
                <h6 className='text-center mb-0'>{stats03.heading}</h6>
            </div>}
        </div>
        <div className='col'>
            {isLoading? <div className='cg-loader w-100'>
                </div> : 
            <div className='p-4 br-5 hvr-shadow'>
                <h3 className='text-center sitecolor-primary mb-2'><CountUp end={stats04.number} duration={5} separator=',' suffix='+' useEasing={true} enableScrollSpy={true}></CountUp></h3>
                <h6 className='text-center mb-0'>{stats04.heading}</h6>
            </div>}
        </div>
    </div>
  )
}
