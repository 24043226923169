import React from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import "./assets/scss/main.scss"
import Homepage from "./pages/Homepage"
import About from './pages/About'
import Header from "./blocks/Header"
import Services from "./pages/Services"
import WebsitePerusahaan from "./services/WebsitePerusahaan"
import FooterCta from './blocks/FooterCta'
import Footer from "./blocks/Footer"
import Contact from "./pages/Contact"
import FreeTrial from "./pages/FreeTrial"
import BuatPenawaran from "./pages/BuatPenawaran"
import GetQuotation from "./pages/GetQuotation"
import ErrorPage from './pages/ErrorPage'
import WebsiteTokoOnline from "./services/WebsiteTokoOnline"
import WebsiteRealEstate from "./services/WebsiteRealEstate"
import WebsiteRentalMobil from "./services/WebsiteRentalMobil"
import WebsiteAgentTravel from "./services/WebsiteAgentTravel"
import WebsiteDealer from "./services/WebsiteDealer"
import WebsiteBookingHotels from "./services/WebsiteBookingHotels"
import WebsiteInstansi from "./services/WebsiteInstansi"
import WebsiteCustom from "./services/WebsiteCustom"
import FiturWebsite from "./pages/FiturWebsite"
import Harga from "./pages/Harga"

function App() {

  return (
    <div className="app">
      <Router>
        <Header />
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<ErrorPage />}></Route>
            <Route path="about" element={<About />}></Route>
            <Route path="services">
              <Route index element={<Services />}></Route>
              <Route path="website-perusahaan" element={<WebsitePerusahaan />} />
              <Route path="website-toko-online" element={<WebsiteTokoOnline />} />
              <Route path="website-real-estate" element={<WebsiteRealEstate />} />
              <Route path="website-rental-mobil" element={<WebsiteRentalMobil />} />
              <Route path="website-agent-travel" element={<WebsiteAgentTravel />} />
              <Route path="website-dealer" element={<WebsiteDealer />} />
              <Route path="website-booking-hotels" element={<WebsiteBookingHotels />} />
              <Route path="website-instansi" element={<WebsiteInstansi />} />
              <Route path="website-custom" element={<WebsiteCustom />} />
            </Route>
            <Route path="/fitur-website" element={<FiturWebsite />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/free-trial" element={<FreeTrial />} />
            <Route path="/buat-penawaran" element={<BuatPenawaran />} />
            <Route path="/get-quotation" element={<GetQuotation />} />
            <Route path="/pricing" element={<Harga />} />
        </Routes>
        <FooterCta />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
