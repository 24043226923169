import React from 'react'
import { Helmet } from 'react-helmet';
import AfterHeader from '../blocks/AfterHeader';
import DynamicWebsite from '../blocks/DynamicWebsite';
import FiturFirst from '../blocks/FiturFirst';
import UniqueDesign from '../blocks/UniqueDesign';
import PortfolioImageSlide from '../subs/PortfolioImageSlide';
import TechnologyUsed from '../blocks/TechnologyUsed'

export default function FiturWebsite() {

    document.title = 'Fitur Website'
   window.scrollTo(0, 0);
  //

  return (
    <>
        <Helmet>
            <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <AfterHeader />
        <FiturFirst />
        <TechnologyUsed />
        <DynamicWebsite />
        <UniqueDesign />
        <div className='container-xxl ws-b'>
            <div className='container center'>
                <p className='accent-text sitecolor-primary text-center'>Modern Design Layout</p>
                <h3 className='text-center'>Website di Desain <span className='sitecolor-primary'>Lebih Modern</span> Dengan Mengikuti Trend Desain</h3>
            </div>
            <PortfolioImageSlide />
        </div>
        
    </>
  )
}
