import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Column from '../utils/Column'

export default function PenawaranBlock() {

    const [isLoading, setIsLoading] = useState(true);

    const parse = require('html-react-parser');

    const [subheading, setSubheading] = useState([])
    const [heading, setHeading] = useState([])
    const [deskripsi, setDeskripsi] = useState([])
    const [cta, setCta] = useState([])

    useEffect(() => {

        const penawaranContent = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2270")
                .then((response) => response.json())
                .then((data) => {
                    setSubheading(data.acf.subheading);
                    setHeading(data.acf.heading);
                    setDeskripsi(data.acf.deskripsi);
                    setCta(data.acf.cta)
                    setIsLoading(!isLoading);
                });}
                
        penawaranContent();
    }, []);

  return (
    <div className='container-fluid p-0 sitebg-accent'>
        <div className='container-xxl ws-tb'>
            {isLoading ? <div className="loader-left bg-transparent loader-penawaran d-flex align-items-center" role="status">
                <div className='container-xxl ps-0 px-0 pe-lg-5'>
                    <div className='lh-first'></div>
                    <div className='lh-second'></div>
                </div>
            </div> : 
            <div className='row'>
                <Column className='d-flex align-items-center' isLeftFirst>
                    <div>
                        <p className='accent-text sitecolor-primary'>{subheading}</p>
                        <h3 className='text-white mb-0'>{parse(`${heading}`)}</h3>
                    </div>
                </Column>
                <Column isRightFirst>
                    <div>
                        <p className='sitecolor-white-tr'>{deskripsi}</p>
                        <Link className='btn-one hvr-float' type='button' to={`${cta.link}`}>{cta.label}</Link>
                    </div>
                </Column>
            </div>}
        </div>
    </div>
  )
}
