import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import Webdev from './dropdown/Webdev'
import Fitur from './dropdown/Fitur'
import Portfolio from './dropdown/Portfolio'

export default function Navmenu() {

    const [isLoading, setIsLoading] = useState(true);

    // API Menu Utama
    const [menus01, setMenus01] = useState([]);
    const [menus02, setMenus02] = useState([]);
    const [menus03, setMenus03] = useState([]);
    const [menus04, setMenus04] = useState([]);

    useEffect(() => {

        const menuUtama = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/5/")
                .then((response) => response.json())
                .then((data) => {
                    setMenus01(data.acf.menu_01); 
                    setMenus02(data.acf.menu_02); 
                    setMenus03(data.acf.menu_03); 
                    setMenus04(data.acf.menu_04); 
                    setIsLoading(!isLoading);
                } ); }

        menuUtama();
    }, []);
    // End API Menu Utama

  return (

    <nav className="navbar navbar-expand-lg py-0">
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        {isLoading? <div className='container center nav-loader'>
          <div className='center-1'></div>
        </div> : 
            <ul className="navbar-nav">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-icon" aria-current="page" to={`${menus01.link_menu}`}>{menus01.nama_menu}</Link>
                    <Webdev />
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-icon" aria-current="page" to={`${menus03.link_menu}`}>{menus03.nama_menu}</Link>
                    <Portfolio />
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link" aria-current="page" to={`${menus02.link_menu}`}>{menus02.nama_menu}</Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link" aria-current="page" to={`${menus04.link_menu}`}>{menus04.nama_menu}</Link>
                </li>
            </ul>}
        </div>
    </nav>
  )
}
