import React from 'react'
import { Helmet } from 'react-helmet'
import AfterHeader from '../blocks/AfterHeader'
import ContactInfo from '../blocks/ContactInfo';

export default function Contact() {

    document.title = 'Contact Us'
    window.scrollTo(0, 0);

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <AfterHeader />
        <ContactInfo />
    </>
  )
}
