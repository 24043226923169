import React from 'react'
import { Helmet } from 'react-helmet'
import Lottie from'lottie-react'
import errorLottie from '../assets/images/404.json'

export default function ErorPage() {

  document.title = 'Page Not Found'
   window.scrollTo(0, 0);
  //

  return (
    <>
        <Helmet>
          <title>{document.title} - Jasa Pembuatan Website Perusahaan Terbaik</title>
        </Helmet>
        <div className='container-xxl py-3'>
            <div className='container center mt-5'>
                <div>
                    <Lottie animationData={errorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        </div>
    </>
  )
}
