import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import Logo from '../assets/images/logo-wikrea.svg'

export default function Mobilemenu() {

    // API Menu Utama
    const [menus01, setMenus01] = useState([]);
    const [menus02, setMenus02] = useState([]);
    const [menus03, setMenus03] = useState([]);
    const [menus04, setMenus04] = useState([]);

    useEffect(() => {

        const menuUtama = () => {
            return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/5/")
                .then((response) => response.json())
                .then((data) => {
                    setMenus01(data.acf.menu_01); 
                    setMenus02(data.acf.menu_02); 
                    setMenus03(data.acf.menu_03); 
                    setMenus04(data.acf.menu_04);
                });}

                
        menuUtama();
    }, []);
    // End API Menu Utama

    // API Menu Webdev
    const [webdev01, setWebdev01] = useState([]);
    const [webdev02, setWebdev02] = useState([]);
    const [webdev03, setWebdev03] = useState([]);
    const [webdev04, setWebdev04] = useState([]);
    const [webdev05, setWebdev05] = useState([]);
    const [webdev06, setWebdev06] = useState([]);
    const [webdev07, setWebdev07] = useState([]);
    const [webdev08, setWebdev08] = useState([]);

    const menuWebdev = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/19/")
            .then((response) => response.json())
            .then((data) => {
                setWebdev01(data.acf.webdev_menu_1);
                setWebdev02(data.acf.webdev_menu_2);
                setWebdev03(data.acf.webdev_menu_3);
                setWebdev04(data.acf.webdev_menu_4);
                setWebdev05(data.acf.webdev_menu_5);
                setWebdev06(data.acf.webdev_menu_6);
                setWebdev07(data.acf.webdev_menu_7);
                setWebdev08(data.acf.webdev_menu_8);
            
            });}

    useEffect(() => {
        menuWebdev();
    }, []);
    // End API Menu Webdev

    // API Menu Fitur
    const [fitur01, setFitur01] = useState([]);
    const [fitur02, setFitur02] = useState([]);
    const menuFitur = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/62/")
            .then((response) => response.json())
            .then((data) => {
                setFitur01(data.acf.fitur_menu_1);
                setFitur02(data.acf.fitur_menu_2);
            
            });}

    useEffect(() => {
        menuFitur();
    }, []);
    // End API Menu Fitur

    // API Menu Portfolio
    const [portfolio01, setPortfolio01] = useState([]);
    const [portfolio02, setPortfolio02] = useState([]);
    const menuPortfolio = () => {
        return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-menu/79/")
            .then((response) => response.json())
            .then((data) => {
                setPortfolio01(data.acf.portfolio_menu_1);
                setPortfolio02(data.acf.portfolio_menu_2);
            });}

    useEffect(() => {
        menuPortfolio();
    }, []);
    // End API Menu Portfolio

    // Mobile Menu Button
    const [isMobileShow, setMobileShow] = useState("false");

    const clickMenuMobile = () => {
        setMobileShow(!isMobileShow);
    };

    // WebDev Menu
    const [isWebdevMobileShow, setWebdevMobileShow] = useState("false");

    const clickWebdevMobile = () => {
        setWebdevMobileShow(!isWebdevMobileShow);
    };

    // FiturMenu
    const [isFiturMobileShow, setFiturMobileShow] = useState("false");

    const clickFiturMobile = () => {
        setFiturMobileShow(!isFiturMobileShow);
    };

    // Portfolio Menu
    const [isPortfolioMobileShow, setPortfolioMobileShow] = useState("false");

    const clickPortfolioMobile = () => {
        setPortfolioMobileShow(!isPortfolioMobileShow);
    };

  return (
    <>
        {/* - Mobile Button - */}
        <div className='d-lg-none d-block'>
            <button className='p-0 border-0' type='button' onClick={() => clickMenuMobile(true)}><span className='fas fa-bars i-stack-medium sitebg-primary text-white'></span></button>
        </div>
        {/* - Mobile Menu - */}
        <div className={isMobileShow ? "mobile-menu" : "mobile-menu show"}>
            <div className="row p-3">
                <div className="d-flex align-items-center col-6 p-0">
                    <div className="header-logo"><img src={Logo}></img></div>
                </div>
                <div className="d-flex justify-content-end align-items-center col-6 p-0">
                    <div className='d-lg-none d-block'>
                        <button className='p-0 border-0 bg-white' type='button' onClick={() => clickMenuMobile(false)}><i className='fas fa-xmark icon-medium sitecolor-primary'></i></button>
                    </div>
                </div>
            </div>
            <div className="mobile-nav p-0 mt-4">
                <div>
                    <ul className='p-0 accent-text mb-0'>
                        <li className='px-3 py-4'>
                            <button className='p-0 border-0 bg-white sitecolor-text dropdown-icon' type='button' onClick={() => clickWebdevMobile(true)}><i className='fas fa-globe icon-small sitecolor-primary me-3'></i>{menus01.nama_menu}</button>
                        </li>
                        <li className='px-3 py-4'>
                            <button className='p-0 border-0 bg-white sitecolor-text dropdown-icon' type='button' onClick={() => clickPortfolioMobile(true)}><i className='fas fa-globe icon-small sitecolor-primary me-3'></i>{menus03.nama_menu}</button>
                        </li>
                        <li className='px-3 py-4'>
                            <Link className='p-0 border-0 bg-white sitecolor-text' onClick={() => {clickMenuMobile(false)}} type='button' to={`${menus02.link_menu}`}><i className='fas fa-globe icon-small sitecolor-primary me-3'></i>{menus02.nama_menu}</Link>
                        </li>
                        <li className='px-3 py-4'>
                            <Link className='p-0 border-0 bg-white sitecolor-text' onClick={() => {clickMenuMobile(false)}} type='button' to={`${menus04.link_menu}`}><i className='fas fa-globe icon-small sitecolor-primary me-3'></i>{menus04.nama_menu}</Link>
                        </li>
                    </ul>

                    <div className={isWebdevMobileShow ? 'sub-mobile webdev' : 'sub-mobile webdev show'}>
                        <div className='head-sub-mobile mb-3 p-3'>
                            <button className='p-0 border-0 bg-white sitecolor-primary' onClick={() => clickWebdevMobile(false)}><i className='fas fa-chevron-left me-2'></i>Go Back</button>
                        </div>
                        <div>
                            <div className='row row-cols-md-2 row-cols-1'>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev01.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev01.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev01.nama_menu}</h6>
                                                <p className='mb-0'>{webdev01.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev02.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev02.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev02.nama_menu}</h6>
                                                <p className='mb-0'>{webdev02.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev03.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev03.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev03.nama_menu}</h6>
                                                <p className='mb-0'>{webdev03.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev04.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev04.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev04.nama_menu}</h6>
                                                <p className='mb-0'>{webdev04.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev05.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev05.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev05.nama_menu}</h6>
                                                <p className='mb-0'>{webdev05.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev06.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev06.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev06.nama_menu}</h6>
                                                <p className='mb-0'>{webdev06.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev07.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev07.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev07.nama_menu}</h6>
                                                <p className='mb-0'>{webdev07.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col p-2'>
                                    <Link className="dropdown-item" onClick={() => {clickWebdevMobile(false); clickMenuMobile(false);}} type="button" to={`${webdev08.link_menu}`}>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start me-2'>
                                                <span className={`${webdev08.font_awesome} icon-medium sitecolor-primary i-stack-medium`}></span></div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{webdev08.nama_menu}</h6>
                                                <p className='mb-0'>{webdev08.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={isPortfolioMobileShow ? 'sub-mobile portfolio' : 'sub-mobile portfolio show'}>
                        <div className='head-sub-mobile mb-3 p-3'>
                            <button className='p-0 border-0 bg-white sitecolor-primary' onClick={() => clickPortfolioMobile(false)}><i className='fas fa-chevron-left me-2'></i>Go Back</button>
                        </div>
                        <div>
                            <div className='row row-cols-1'>
                                <div className='col p-2'>
                                    <a className="dropdown-item" onClick={() => {clickPortfolioMobile(false); clickMenuMobile(false)}} type="button" href={`${portfolio01.link_menu}`} target='_blank'>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start envato-icon me-3'>
                                                <img className='w-100' src={`${portfolio01.image_icon}`}></img>
                                            </div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{portfolio01.nama_menu}</h6>
                                                <p className='mb-0'>{portfolio01.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col p-2'>
                                    <a className="dropdown-item" onClick={() => {clickPortfolioMobile(false); clickMenuMobile(false)}} type="button" href={`${portfolio02.link_menu}`} target='_blank'>
                                        <div className='d-flex align-items-center'>
                                            <div className='float-start envato-icon me-3'>
                                                <img className='w-100' src={`${portfolio02.image_icon}`}></img>
                                            </div>
                                            <div className='float-start'>
                                                <h6 className='mb-1'>{portfolio02.nama_menu}</h6>
                                                <p className='mb-0'>{portfolio02.deskripsi_menu}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='btn-menu row'>
                <div className='col-6 ps-0 pe-2'>
                    <Link className='btn-one w-100 px-2 text-center py-3 hvr-float' onClick={() =>clickMenuMobile(false)} type='button' to='/contact'>Hubungi Kami</Link>
                </div>
                <div className='col-6 ps-2 pe-0'>
                    <Link className='btn-border w-100 px-2 text-center py-3 hvr-float' onClick={() =>clickMenuMobile(false)} type='button' to='/about'>Tentang Kami</Link>
                </div>
                <div className='col-12 pt-3 ps-0 pe-0'>
                    <Link className='btn-two w-100 px-2 text-center py-3 hvr-float' onClick={() =>clickMenuMobile(false)} type='button' to='/free-trial'><i className='far fa-clone me-2'></i>Coba Gratis</Link>
                </div>
            </div>
        </div>
    </>
  )
}
