import React, {useEffect} from 'react'
import BusinessNumber from '../blocks/BusinessNumber'
import DynamicWebsite from '../blocks/DynamicWebsite'
import FiturUtama from '../blocks/FiturUtama'
import Hero from '../blocks/Hero'
import OurServices from '../blocks/OurServices'
import PenawaranBlock from '../blocks/PenawaranBlock'
import PortfolioSlider from '../blocks/PortfolioSlider'
import TechnologyUsed from '../blocks/TechnologyUsed'
import UniqueDesign from '../blocks/UniqueDesign'

export default function Homepage() {

  useEffect(() => {
      document.title = 'PT Wira Industri Kreatif - Jasa Pembuatan Website Perusahaan Terbaik'
      window.scrollTo(0, 0);
  }, []);
  //

  return (
    <>
      <div>
        <Hero />
        <PortfolioSlider />
        <DynamicWebsite />
        <OurServices />
        <PenawaranBlock />
        <FiturUtama />
        <UniqueDesign />
        <BusinessNumber />
        <TechnologyUsed />
      </div>
    </>
  )
}
