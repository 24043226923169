import React, {useState, useEffect} from 'react'
import CounterBusiness from '../subs/CounterBusiness';

export default function BusinessNumber() {

  const [isLoading, setIsLoading] = useState(true);

  const parse = require('html-react-parser');

  // API Portfolio
  const [subheading, setSubheading] = useState([]);
  const [heading, setHeading] = useState([]);

  useEffect(() => {
    const numberStats = () => {
      return fetch("https://aio.wikrea.co.id/wp-json/wp/v2/wikrea-blocks/2313")
          .then((response) => response.json())
          .then((data) => {
            setSubheading(data.acf.subheading);
            setHeading(data.acf.heading);
            setIsLoading(!isLoading)
          });}
          
      numberStats();
  }, []);
  //

  return (
    <>
      <div className='container-xxl ws-b'>
        {isLoading? <div className='container center loader-center mb-4'>
            <div className='center-1'></div>
            <div className='center-2'></div>
          </div> : 
          <div className='container center pt-4 mb-lg-5 mb-3'>
              <p className='accent-text sitecolor-primary text-center'>{subheading}</p>
              <h3 className='text-center'>{parse(`${heading}`)}</h3>
          </div>}
          <CounterBusiness />
      </div>
    </>
  )
}
